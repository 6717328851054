@import '@aws-amplify/ui-react/styles.css';

body {
  margin: 0;
  background-color: #282c34;
}

[data-amplify-authenticator] {
  --amplify-colors-brand-primary-10: var(--amplify-colors-green-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-green-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-green-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-green-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-green-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-green-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-green-100);
}

.amplify-button[data-variation='primary'] {
  background-color: var(--amplify-colors-brand-primary-60);
}

.amplify-button[data-variation='primary']:hover,
.amplify-button[data-variation='primary']:focus {
  background-color: var(--amplify-colors-brand-primary-80);
}

.amplify-button[data-variation='primary']:active {
  background-color: var(--amplify-colors-brand-primary-90);
}

.amplify-button[data-variation='link'] {
  color: var(--amplify-colors-brand-primary-80);
}

.amplify-button[data-variation='link']:hover,
.amplify-button[data-variation='link']:focus {
  color: var(--amplify-colors-brand-primary-80);
  background-color: var(--amplify-colors-brand-primary-10);
}
